import { computed } from '@vue/composition-api'
import axios from '@axios'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import store from '@/store'

export default function useVerticalNavMenuSchoolSwitcher() {
  // Use toast
  const toast = useToast()

  const programOptions = computed(() => store.getters['verticalMenu/getProgramOptions'])

  const switchSchool = queryParams => axios
    .post('/auth/admin/switch-school', queryParams)
    .then(response => {
      toast({
        component: ToastificationContent,
        props: {
          title: 'School was successfully switched',
          icon: 'ThumbsUpIcon',
          variant: 'info',
        },
      })
      return response.data.data
    })
    .catch(() => toast({
      component: ToastificationContent,
      props: {
        title: 'Error switching School',
        icon: 'AlertTriangleIcon',
        variant: 'danger',
      },
    }))

  return {
    switchSchool,
    programOptions,
  }
}
