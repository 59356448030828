<template>
  <b-dropdown
    variant="light"
    class="m-1"
    menu-class="w-100"
    block
    no-caret
    dropup
  >
    <template #button-content>
      <div class="d-flex justify-content-between align-items-center">
        <div class="d-flex align-items-center">
          <b-img
            :src="schoolData.config.logo_url"
            width="30px"
          />
          <span class="ml-1">{{ schoolData.name }}</span>
        </div>
        <feather-icon
          icon="ChevronRightIcon"
        />
      </div>
    </template>

    <b-dropdown-item
      v-for="school in userData.schools.filter(item => item.is_active)"
      :key="school.id"
      @click="changeSchool(school.id)"
    >
      <div class="d-flex align-items-center">
        <b-img
          :src="school.config.logo_url"
          width="30px"
          alt=""
        />
        <span class="ml-1">{{ school.name }}</span>
      </div>
    </b-dropdown-item>
  </b-dropdown>
</template>

<script>
import { BDropdown, BDropdownItem, BImg } from 'bootstrap-vue'

import useVerticalNavMenuSchoolSwitcher from '@core/layouts/layout-vertical/components/vertical-nav-menu/components/vertical-nav-menu-school-switcher/useVerticalNavMenuSchoolSwitcher'

import { setUserData } from '@core/mixins/setUserData'
import { setCurrentUserData } from '@/auth/utils'

export default {
  name: 'VerticalNavMenuSchoolSwitcher',
  components: {
    BDropdown,
    BDropdownItem,
    BImg,
  },
  mixins: [setUserData],
  setup() {
    const {
      switchSchool,
      programOptions,
    } = useVerticalNavMenuSchoolSwitcher()
    return {
      switchSchool,
      programOptions,
    }
  },
  mounted() {
    this.setUserData()
  },
  methods: {
    async changeSchool(id) {
      try {
        const queryParams = {
          school_id: id,
        }
        const appLoading = document.getElementById('loading-bg')

        if (appLoading) {
          appLoading.style.display = 'block'
        }

        await this.switchSchool(queryParams)

        await setCurrentUserData()

        this.schoolData = JSON.parse(localStorage.getItem('schoolData'))
        this.schoolData = localStorage.getItem('currentSchoolId')
        this.$router.push({
          query: {
            school_id: localStorage.getItem('currentSchoolId'),
          },
        })
        await this.$store.dispatch('verticalMenu/fetchProgramOptions')

        const defaultProgram = this.programOptions[0]?.id || ''
        this.$store.commit('verticalMenu/UPDATE_DEFAULT_PROGRAM', defaultProgram)

        localStorage.removeItem('widgetCards')

        if (this.$router.currentRoute.name === 'admin-dashboard') {
          this.$router.go()
        } else {
          this.$router.push({ name: 'admin-dashboard' }).then(() => this.$router.go())
        }
        return true
      } catch (error) {
        return error
      }
    },
  },
}
</script>
